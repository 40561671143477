const TAGS_SEARCH_PANELS_VISIBILITY_TOKEN = 'tagsSearchPanelsVisibility';

export const TagsSearchLocalStorageService = {
  getTagsSearchPanelsVisibility: () => JSON.parse(
    window.localStorage.getItem(TAGS_SEARCH_PANELS_VISIBILITY_TOKEN) || '{}',
  ),

  setTagsSearchPanelsVisibility: nextState => window.localStorage.setItem(
    TAGS_SEARCH_PANELS_VISIBILITY_TOKEN,
    JSON.stringify(nextState),
  ),
};

export default TagsSearchLocalStorageService;
