<template>
  <vbt-content-box
    :loading="loading"
    :hide-content="isHidedBlock"
    hide-content-on-header-click
    title="Search by tags"
  >
    <template #header>
      <strong>
        Search by tags
      </strong>
    </template>

    <v-layout
      class="mb-4"
      align-center
      justify-end
    >
      <strong>
        Tags popularity heatmap:
      </strong>

      <div
        v-for="heatmapInfoKey in Object.keys(TAGS_HEATMAP)"
        :key="`tags_heatmap_${heatmapInfoKey}`"
        style="display: flex; align-items: center;"
        class="ml-3"
      >
        {{ heatmapInfoKey }}
        <div
          :class="TAGS_HEATMAP[heatmapInfoKey]"
          style="width: 15px; height: 15px;"
          class="ml-2"
        />
      </div>
    </v-layout>

    <v-layout
      v-for="categoryWithTags in categoriesWithTags"
      :key="categoryWithTags.category"
      column
    >
      <v-layout
        justify-space-between
        style="cursor: pointer;"
        @click="changePanelVisibility(categoryWithTags.category)"
      >
        {{ categoryWithTags.description || '-' }}

        <v-icon small>
          {{ panelsVisibility[categoryWithTags.category] ? 'expand_less' : 'expand_more' }}
        </v-icon>
      </v-layout>

      <v-divider class="mt-2 mb-3" />

      <v-flex
        v-show="panelsVisibility[categoryWithTags.category]"
        sm12
      >
        <v-chip
          v-for="tag in categoryWithTags.tags"
          :key="tag.tagKey"
          :class="{
            'success': selectedPositiveTags.includes(tag.tagKey),
            'error': selectedNegativeTags.includes(tag.tagKey),
            [tag.tagColor]: !selectedPositiveTags.includes(tag.tagKey)
              && !selectedNegativeTags.includes(tag.tagKey),
          }"
          small
          class="mr-2 mb-3"
          style="cursor: pointer;"
          @click="updateSelectedTags(tag.tagKey)"
        >
          <strong>
            <template v-if="selectedNegativeTags.includes(tag.tagKey)">
              not
            </template>
            #{{ tag.tagName }}
          </strong>
        </v-chip>
      </v-flex>
    </v-layout>

    <v-layout
      justify-space-between
      class="mt-3"
    >
      <v-btn
        small
        color="info"
        @click="search"
      >
        Search
      </v-btn>
      <v-btn
        text
        small
        @click="resetSearch"
      >
        Reset
      </v-btn>
    </v-layout>
  </vbt-content-box>
</template>

<script>
import { wrapToLoadingFn } from '@helpers';

import {
  getTagsArrayFromQueryValue,
  prepareTagsData,
  useSortByAlphabet,
  TAGS_HEATMAP,
} from '../_helpers/tags';

import { OrdersApiService } from '../_services/orders.api.service';

import { TagsSearchLocalStorageService } from '../_services/tagsSearch.localStorage.service';

export default {
  name: 'IrOrdersSearchByTags',

  data() {
    return {
      categoriesWithTags: [],
      selectedPositiveTags: [],
      selectedNegativeTags: [],

      isHidedBlock: !getTagsArrayFromQueryValue(this.$route.query.tags).length,
      panelsVisibility: {},
      loading: false,

      TAGS_HEATMAP,
    };
  },

  watch: {
    '$route.query.tags': {
      handler(tags) {
        this.selectedPositiveTags = getTagsArrayFromQueryValue(tags);
      },
      immediate: true,
    },

    '$route.query.notInTags': {
      handler(tags) {
        this.selectedNegativeTags = getTagsArrayFromQueryValue(tags);
      },
      immediate: true,
    },
  },

  created() {
    this.getCategoriesWithTags();
  },

  methods: {
    search() {
      this.$emit('search', {
        positive: this.selectedPositiveTags,
        negative: this.selectedNegativeTags,
      });
    },

    resetSearch() {
      this.$emit('reset');

      this.selectedPositiveTags = [];
      this.selectedNegativeTags = [];
    },

    updateSelectedTags(tag) {
      if (this.selectedPositiveTags.includes(tag)) {
        this.selectedPositiveTags = this.selectedPositiveTags.filter(t => t !== tag);
        this.selectedNegativeTags.push(tag);
        return;
      }

      if (this.selectedNegativeTags.includes(tag)) {
        this.selectedNegativeTags = this.selectedNegativeTags.filter(t => t !== tag);
        return;
      }

      this.selectedPositiveTags.push(tag);
    },

    changePanelVisibility(panelKey) {
      this.panelsVisibility[panelKey] = !this.panelsVisibility[panelKey];
      TagsSearchLocalStorageService.setTagsSearchPanelsVisibility(this.panelsVisibility);
    },

    initPanelsVisibility(panels) {
      const panelsVisibility = TagsSearchLocalStorageService.getTagsSearchPanelsVisibility();

      panels.forEach((panel) => {
        panelsVisibility[panel.category] = panelsVisibility[panel.category] || panel.tags
          .some(({ tagKey }) => [
            ...this.selectedPositiveTags,
            ...this.selectedNegativeTags,
          ].includes(tagKey));
      });

      TagsSearchLocalStorageService.setTagsSearchPanelsVisibility(panelsVisibility);
      this.$set(this, 'panelsVisibility', panelsVisibility);
    },

    getCategoriesWithTags() {
      this.wrapToLoadingFn({
        req: OrdersApiService.getCategoriesWithTags,
        loadingFlagName: 'loading',
        onSuccess: (r) => {
          this.categoriesWithTags = r
            .sort(useSortByAlphabet('description'))
            .reduce((acc, category) => {
              acc.push({ ...category, tags: prepareTagsData(category.tags, category.category) });

              return acc;
            }, []);

          this.initPanelsVisibility(this.categoriesWithTags);
        },
      });
    },

    wrapToLoadingFn,
  },
};
</script>
